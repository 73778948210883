<template>
  <div class="header-block-measure">
    <h2 class="title title--h2 title--measure-block" v-html="title"></h2>
    <slot>
      <div class="header-block-measure__interaction">
        <div class="header-block-measure__interaction-left">
          <ButtonComponent mod="gradient-bg" class="button-redesign--mobile-full" @click="onCreate"
            >Создать</ButtonComponent
          >
          <ButtonComponent v-if="!checkedSwitch" mod="gradient-bg" class="button-redesign--mobile-full" @click="onClear"
            >Очистить фильтр</ButtonComponent
          >
          <div class="header-block-measure__checkbox" v-if="unilateral && !changeLog">
            <ComponentInput
              id="chk-grouping"
              type="checkbox"
              label="Группировать"
              mod="checkbox-green"
              v-model="grouping"
            />
          </div>
          <DefaultFilter v-if="saveFilter && !checkedSwitch" :filterValue="filterValue" @loadFilter="onLoadFilter" />
        </div>
        <div v-if="!checkedSwitch" class="header-block-measure__interaction-right">
          <ButtonComponent v-if="exportButton" mod="gradient-bg" class="button-redesign--mobile-full" @click="onExport"
            >Экспорт</ButtonComponent
          >
          <ButtonComponent v-if="importButton" mod="gradient-bg" class="button-redesign--mobile-full" @click="onImport"
            >Импорт</ButtonComponent
          >
        </div>
      </div>
      <div class="header-block-measure__interaction">
        <div v-if="!changeLog && !checkedSwitch" class="header-block-measure__interaction-left">
          <template v-if="!modifiedRangeData?.isActive">
            <ButtonComponent v-if="bulkEditingVisible" class="button-redesign--mobile-full" @click="onEditView"
              >Вид отображения</ButtonComponent
            >
            <ButtonComponent
              v-if="bulkEditingVisible"
              :disabled="!selectedIds.length"
              class="button-redesign--mobile-full"
              @click="onBulkEditing"
              >Массовое редактирование</ButtonComponent
            >
          </template>
          <p v-else>{{ modifiedRangeData.filterText }}</p>
        </div>
        <div class="header-block-measure__interaction-right">
          <template v-if="modifiedRangeDataVisible">
            <ButtonComponent
              v-if="!modifiedRangeData?.isActive"
              mod="border-grey"
              class="button-redesign--mobile-full"
              @click="onChangesPeriod"
              >Показать изменения за период</ButtonComponent
            >
            <ButtonComponent v-else mod="border-grey" class="button-redesign--mobile-full" @click="onBackRegistry"
              >Вернуться к реестру</ButtonComponent
            >
            <ButtonComponent
              v-if="changeLog"
              mod="border-grey"
              class="button-redesign--mobile-full"
              @click="onBackChangesOverPeriod"
              >Вернуться к изменениям за период</ButtonComponent
            >
          </template>
          <RedesignedSwitchComponent
            v-if="switchComponent"
            class="switch-back--big"
            textTrue="Карточки"
            textFalse="Таблица"
            :checked="checkedSwitch"
            @switchHandler="switchHandler"
          />
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput.vue';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';

  export default {
    name: 'HeaderBlock',
    emits: [
      'create',
      'clear',
      'export',
      'import',
      'bulkEditing',
      'changesPeriod',
      'backRegistry',
      'changeValueGrouping',
      'onBackChangesOverPeriod',
      'onLoadFilter',
      'switchHandler',
      'editView',
    ],
    components: {
      ComponentInput,
      ButtonComponent,
      DefaultFilter,
      RedesignedSwitchComponent,
    },
    props: {
      title: {
        type: String,
      },
      modifiedRangeData: {
        type: Object,
      },
      modifiedRangeDataVisible: {
        type: Boolean,
        default: false,
      },
      unilateral: {
        type: Boolean,
        default: false,
      },
      bulkEditingVisible: {
        type: Boolean,
        default: true,
      },
      changeLog: {
        type: Boolean,
        default: false,
      },
      selectedIds: {
        type: Array,
        default: () => [],
      },
      saveFilter: {
        type: Boolean,
        default: false,
      },
      filterValue: {
        type: Object,
        default: () => {},
      },
      exportButton: {
        type: Boolean,
        default: true,
      },
      importButton: {
        type: Boolean,
        default: true,
      },
      switchComponent: {
        type: Boolean,
        default: false,
      },
      checkedSwitch: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        grouping: false,
      };
    },
    watch: {
      grouping(val) {
        this.$emit('changeValueGrouping', val);
      },
    },
    methods: {
      onCreate() {
        this.$emit('create');
      },
      onClear() {
        this.$emit('clear');
      },
      onExport() {
        this.$emit('export');
      },
      onImport() {
        this.$emit('import');
      },
      onEditView() {
        this.$emit('editView');
      },
      onBulkEditing() {
        this.$emit('bulkEditing');
      },
      onChangesPeriod() {
        this.$emit('changesPeriod');
      },
      onBackRegistry() {
        this.$emit('backRegistry');
      },
      onBackChangesOverPeriod() {
        this.$emit('onBackChangesOverPeriod');
      },
      onLoadFilter(item) {
        this.$emit('onLoadFilter', item);
      },
      switchHandler(state) {
        this.$emit('switchHandler', state);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  .header-block-measure {
    margin-bottom: 24px;

    :slotted(.header-block-measure__interaction) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      gap: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        font: $fira-16;
      }
    }

    :slotted(.header-block-measure__interaction-left),
    :slotted(.header-block-measure__interaction-right) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;
    }

    :slotted(.header-block-measure__interaction-right) {
      margin-left: auto;
    }

    @media (max-width: $mobile) {
      :slotted(.header-block-measure__interaction) {
        margin-bottom: 16px;
        gap: 16px 8px;
      }

      :slotted(.header-block-measure__interaction-left),
      :slotted(.header-block-measure__interaction-right) {
        width: 100%;
        gap: 8px;
      }
    }

    &--initiatives {
      @media (max-width: $laptop) {
        :slotted(.header-block-measure__interaction) {
          margin-bottom: 16px;
          gap: 16px 8px;
        }

        :slotted(.header-block-measure__interaction-left),
        :slotted(.header-block-measure__interaction-right) {
          width: 100%;
          gap: 8px;
        }
      }
    }

    &.combine {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        max-width: 100%;
        width: 100%;
      }

      @media (max-width: $mobile) {
        flex-direction: column;
      }
    }
  }
</style>
